const isInternational = (connection?: Connection): boolean => {
  /* istanbul ignore next */
  if (connection == null) return false

  return connection.arrivalStation.code.slice(0, 2) !== connection.departureStation.code.slice(0, 2)
}

const getVehicleIcon = (code?: Vehicle.Code): string => {
  /* istanbul ignore next */
  switch (code) {
    case 'TRAIN':
      return 'train'
    case 'TRAM':
      return 'taxi'
    case 'FERRY':
      return 'ferry'
    case 'BUS':
      return 'bus'
    default:
      return 'map-pin'
  }
}

const SELF_TRANSFER = 'self transfer'

const isSelfTransfer = (segment?: Segment): boolean => segment?.linePrefix?.toLowerCase() === SELF_TRANSFER

export interface Emission {
  typicalEmission: number
  currentEmission: number
  savingPercentage: number
}

const calculateAverage = (connections: Connection[], key: keyof Connection): number =>
  Math.round(connections.reduce((sum, item) => sum + Number(item[key]), 0) / connections.length)

const calculateEmission = (connections: Connection[]): Emission | null => {
  const filtered = connections.filter(item => Boolean(item.co2EKg) && Boolean(item.co2ESavings) && Boolean(item.co2Kg))

  if (!filtered.length) return null

  const typicalEmission = calculateAverage(filtered, 'co2EKg') + calculateAverage(filtered, 'co2ESavings')
  const currentEmission = calculateAverage(filtered, 'co2EKg')
  const savingPercentage = Math.round((calculateAverage(filtered, 'co2ESavings') / typicalEmission) * 100)

  return { typicalEmission, currentEmission, savingPercentage }
}

const updateBookingConnection = (connection: Connection | null, fareClasses: FareClass[]): Connection | null => {
  if (!connection) return null

  return {
    ...connection,
    cheapestFareClassCode: fareClasses[0].code,
    fares: [],
    segments: connection.segments.map(segment => ({
      ...segment,
      marketingCarrier: { ...segment.marketingCarrier, fareClasses },
    })),
  }
}

const getPrice = (connection: Connection, fare?: Fare | null): Money => {
  const connectionPrice = fare?.price ?? connection.cheapestTotalAdultPrice

  return {
    ...connectionPrice,
    fractional: connectionPrice.fractional - connection.marketingCarrier.bookingFee.fractional,
  }
}

const calculateTotalPrice = (list: (Connection | null)[], currency: Currency): Money => {
  const fractional = list.reduce((acc, cur) => acc + (cur?.cheapestTotalAdultPrice.fractional ?? 0), 0)
  return { fractional, currency }
}

const getDepartureDate = (data?: string): string | undefined => {
  const match = data?.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/)

  return match?.[0]
}

const getTimeRange = ({ id }: Connection): string => {
  const timePattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/

  return id.match(timePattern)?.[0] ?? /* istanbul ignore next */ ''
}

export default {
  isInternational,
  getVehicleIcon,
  isSelfTransfer,
  calculateEmission,
  updateBookingConnection,
  getPrice,
  calculateTotalPrice,
  getTimeRange,
  getDepartureDate,
}
