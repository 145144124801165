import { ConnectionsLoaderParams } from '@loaders/connections'

interface LocationInterface {
  name: string
  countryCode?: string | null
  subdivisionCode?: string | null
}

export interface ParamsLocations {
  departureLocation: Location.Item | null
  arrivalLocation: Location.Item | null
}

const checkType = (location: Location.Item | null, type: string): boolean => location?.type === type

const getCityCode = (location: Location.Item | null): Location.Item | null => {
  /* istanbul ignore next */
  if (!location) return null

  return {
    type: 'city',
    code: location.code.slice(0, 5),
  }
}

const makeDisplayName = (location: LocationInterface): string => {
  const { name, countryCode, subdivisionCode } = location
  const elements = [name]

  if (subdivisionCode) elements.push(subdivisionCode)
  if (countryCode) elements.push(countryCode)

  return elements.join(', ')
}

const getRetryLocations = (params: ConnectionsLoaderParams): ParamsLocations | null => {
  const { departureLocation, arrivalLocation } = params
  const departureCity = getCityCode(departureLocation)
  const arrivalCity = getCityCode(arrivalLocation)
  const isStations = checkType(arrivalLocation, 'station') && checkType(departureLocation, 'station')

  if (isStations) return { departureLocation: departureCity, arrivalLocation: arrivalCity }
  if (!checkType(arrivalLocation, 'city')) return { departureLocation, arrivalLocation: arrivalCity }
  if (!checkType(departureLocation, 'city')) return { departureLocation: departureCity, arrivalLocation }

  return null
}

const locationUtils = {
  makeDisplayName,
  getRetryLocations,
  getCityCode,
}

export default locationUtils
