import initAlienUtils from '@wasm/alien_utils'
import React from 'react'

import sdkBuilder from '@lib/sdk/builder'
import wasmUtils from '@lib/wasm'
import App from '@sdk/Route/App'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

export interface SDKSearchParams {
  root: HTMLElement
  locale: Locale
  partnerNumber: RetailerNumber
  carrierCode: CarrierCode
  departureStation: StationCode
  arrivalStation: StationCode
  target?: SDK.Target
  currency?: Currency
  extraElements?: HTMLElement[]
}

export interface SDKSearchObject {
  unmount: () => void
}

const mount = ({ root, extraElements, ...props }: SDKSearchParams): SDKSearchObject => {
  const params = {
    ...props,
    locale: props.locale ?? /* istanbul ignore next */ 'en',
    target: props.target ?? 'current',
    partnerNumber: Number(props.partnerNumber),
  }

  const controller = sdkBuilder.build(root, { extraElements })
  wasmUtils.withWasmSupport(initAlienUtils, () => {
    controller.render(
      <PopupContainerContext.Provider value={controller.popupContainer}>
        <App {...params} />
      </PopupContainerContext.Provider>,
    )
  })

  return {
    unmount: (): void => {
      controller.unmount()
    },
  }
}

export default {
  mount,
}
