import { useMemo } from 'react'

import { VacancyRequest } from '@api/vacancy'
import useKavanaghIntegration from '@hooks/useKavanaghIntegration'
import date from '@lib/date'
import passengersUtils from '@lib/passengers'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'
import { PassengerData, useCheckout } from '@stores/checkout'
import { useCarrierSpecificParams } from '@stores/params'

const filterPassengers = (passengers: PassengerData[]): PassengerData[] =>
  passengersUtils.filterPassengers(passengers).reduce<PassengerData[]>((acc, { type, cards, pax }) => {
    const selected = acc.find(p => p.type === type && !p.cards?.length)

    if (cards?.length || !selected) return [...acc, { type, pax, cards }]

    selected.pax += 1

    return acc
  }, [])

export const useVacancyProps = (passengers: PassengerData[]): VacancyRequest => {
  const [params] = useCarrierSpecificParams()
  const [{ outbound }] = useCheckout()
  const [{ ancillaries }] = useSettings()
  const { isJJKEnabled, updateJJKPassengers } = useKavanaghIntegration()

  const passengerList = useMemo(() => {
    if (isJJKEnabled) return filterPassengers(updateJJKPassengers(passengers))

    return filterPassengers(passengers)
  }, [isJJKEnabled, passengers, updateJJKPassengers])

  return useMemo(() => {
    const passengers = passengerList.map(pax => ({ ...pax, cards: passengersUtils.getPaxCards(pax.cards) }))
    const departure = outbound ? date.formatTime(date.parse(outbound.departureTime, 'UTC')) : params.departureTime
    const arrival = outbound ? date.formatTime(date.parse(outbound.arrivalTime, 'UTC')) : params.arrivalTime

    return utils.object.compact<VacancyRequest>({
      marketingCarrierCode: params.marketingCarrierCode,
      retailerPartnerNumber: params.retailerPartnerNumber,
      departureStationCode: params.departureLocation?.code,
      arrivalStationCode: params.arrivalLocation?.code,
      currency: params.currency,
      locale: params.locale,
      fareClass: params.fareClass,
      passengers,
      departureDate: params.departureDate,
      departureTime: departure,
      arrivalDate: params.arrivalDate,
      arrivalTime: arrival,
      returnDepartureDate: params.returnDepartureDate,
      returnDepartureTime: params.returnDepartureTime,
      returnArrivalDate: params.returnArrivalDate,
      returnArrivalTime: params.returnArrivalTime,
      include: ancillaries.enabled ? 'ancillary_options' : null,
      cards: params.cards,
    })
  }, [outbound, ancillaries, params, passengerList])
}
