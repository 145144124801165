import React, { ReactElement } from 'react'

import bem from '@lib/bem'

import '@ui/Tabs/index.scss'

interface TabsProps<T> {
  options: Option<T>[]
  value: T
  variant?: 'outlined' | 'primary'
  onClick: (key: T) => void
}

const Tabs = <T extends string | number>({
  variant = 'primary',
  options,
  onClick,
  value,
}: TabsProps<T>): ReactElement => {
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: T): void => {
    if (event.key === 'Enter') onClick(index)
  }

  const headerWrapperClasses = bem('ui-tabs', 'header-wrapper', { outlined: variant === 'outlined' })
  const labelClasses = (item: string | number): string => bem('ui-tabs', `header-label`, { active: value === item })

  return (
    <div className={bem('ui-tabs', { [variant]: true })}>
      <div className="ui-tabs__header row">
        <div className={headerWrapperClasses}>
          {options?.map(item => (
            <div
              key={item.value}
              tabIndex={0}
              className={labelClasses(item.value)}
              onKeyDown={event => {
                handleOnKeyDown(event, item.value)
              }}
              onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault()
              }}
              onClick={() => {
                onClick(item.value)
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tabs
