import { GovernmentIdParams } from '@api/booking'
import { createStore, useStore, UseStoreHook } from '@lib/store'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'

export interface PassengerData extends GovernmentIdParams {
  firstName?: string
  lastName?: string
  type: string
  id?: number | string
  pax: number
  birthdate?: string | null
  gender?: string
  cards?: DiscountCode.Card[]
  maxAge?: number
  minAge?: number
}

export interface ContactData {
  email: string
  phone: string | null
  confirmEmail: string | null
}

export interface CheckoutStore {
  outbounds: Connection[] | null
  outbound: Connection | null
  inbound: Connection | null
  bookingFormId?: number | null
  paymentInstance: PaymentMethod | null
}

export interface AccountData {
  email: string
  password: string | null
  confirmPassword: string | null
}

const store = createStore<CheckoutStore>({ outbounds: null, outbound: null, inbound: null, paymentInstance: null })

export const useCheckout = (): UseStoreHook<CheckoutStore> => useStore(store)

export default store
