import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'
import { RadioGroup } from '@ui'

export enum DirectionType {
  OneWay = 'oneWay',
  Return = 'return',
  Weekly = 'weekly',
}

interface TripDirectionProps {
  value: DirectionType
  layout: Direction
  oneWayLabel?: string
  twoWayLabel?: string
  weeklyLabel?: string
  onChange: (value: DirectionType) => void
  twoWay?: boolean
  weekly?: boolean
}

const TripDirection = (props: TripDirectionProps): ReactElement => {
  const { value, oneWayLabel, twoWayLabel, weeklyLabel, onChange, layout, weekly, twoWay = true } = props
  const { t } = useTranslation()

  const types = [
    { label: oneWayLabel ?? t('components.direction.oneWay'), value: DirectionType.OneWay, centered: true },
  ]

  /* istanbul ignore else */
  if (twoWay)
    types.push({ label: twoWayLabel ?? t('components.direction.return'), value: DirectionType.Return, centered: true })

  if (weekly)
    types.push({ label: weeklyLabel ?? t('components.direction.weekly'), value: DirectionType.Weekly, centered: true })

  return <RadioGroup direction={layout} value={value} onChange={onChange} options={types} />
}

export default TripDirection
