import { FieldProps } from 'formik'
import React, { ReactElement } from 'react'

import TripDirection, { DirectionType } from '@components/TripDirection/index'

interface Props extends FieldProps<DirectionType> {
  twoWay: boolean
  weekly: boolean
  onChange?: (direction: DirectionType) => void
}

const Field = ({ field, form, twoWay, weekly, onChange }: Props): ReactElement => {
  return (
    <TripDirection
      layout="row"
      value={field.value}
      twoWay={twoWay}
      weekly={weekly}
      onChange={value => {
        onChange?.(value)
        form.setFieldValue(field.name, value)
      }}
    />
  )
}

export default Field
