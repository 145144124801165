import { ABTestSettings } from '@api/settings'
import amplitude from '@lib/analytics/amplitude'
import testing from '@lib/testing'

const buildKey = (feature: AbTestFeature): string => `ab_test.${feature}`
const featureState = {
  has: (feature: AbTestFeature | null): boolean => feature != null && !!localStorage.getItem(buildKey(feature)),
  disabled: (feature: AbTestFeature | null): boolean =>
    feature != null && featureState.has(feature) && localStorage.getItem(buildKey(feature)) === 'false',
}

const abTestConfig = testing.getDependency('abTestConfig') ?? {
  chance: 0.5,
}

const decideParticipation = ({ enabled, feature }: ABTestSettings): void => {
  if (!enabled || !feature || featureState.has(feature)) return

  const featureEnabled = Math.random() < abTestConfig.chance ? 'true' : 'false'
  localStorage.setItem(buildKey(feature), featureEnabled)

  if (featureEnabled === 'true') {
    amplitude.identify({ append: { ab_test: feature } })
  }
}

const abTest = { featureState, decideParticipation }

export default abTest
