import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

import discountCardsAPI, { DiscountCardsResponse } from '@api/discountCards'
import config from '@config'
import { useParams } from '@stores/params'

export const useDiscounts = (
  options?: Partial<UseQueryOptions<DiscountCardsResponse>>,
  types?: DiscountCard.Type[],
) => {
  const [{ retailerPartnerNumber, marketingCarrierCode, locale }] = useParams()

  const marketingCarrierCodes = useMemo(() => {
    const retailerCarriers = config.retailerDiscounts[retailerPartnerNumber]?.discounts ?? []
    return [marketingCarrierCode, ...retailerCarriers].filter<string>((value): value is string => value != null)
  }, [marketingCarrierCode, retailerPartnerNumber])

  return useQuery({
    queryKey: ['discounts', locale, retailerPartnerNumber, marketingCarrierCodes, types],
    queryFn: () =>
      discountCardsAPI.load({
        locale,
        retailerPartnerNumber,
        types: types ?? ['discount_card', 'loyalty'],
        marketingCarrierCodes,
      }),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60,
    ...options,
  })
}
