import { Campaign } from '@amplitude/analytics-types/lib/esm/campaign'
import React from 'react'

import sdkBuilder from '@lib/sdk/builder'
import App from '@sdk/Search/App'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

export interface SDKSearchParams {
  root: HTMLElement
  locale: Locale
  partnerNumber: number
  layout?: Direction
  target?: SDK.Target
  carrierCodes?: string[]
  currency?: Currency
  defaults?: {
    departureStation?: string
    departureArea?: string
    departureCity?: string
    arrivalStation?: string
    arrivalArea?: string
    arrivalCity?: string
  }
  utm?: Campaign
  extraElements?: HTMLElement[]
}

export interface SDKSearchObject {
  unmount: () => void
}

const mount = ({ root, extraElements, ...props }: SDKSearchParams): SDKSearchObject => {
  root.style.containerType = 'inline-size'
  root.style.width = '100%'
  const params = {
    ...props,
    locale: props.locale ?? /* istanbul ignore next */ 'en',
    target: props.target ?? 'current',
    partnerNumber: Number(props.partnerNumber),
  }

  const controller = sdkBuilder.build(root, { extraElements })
  controller.render(
    <PopupContainerContext.Provider value={controller.popupContainer}>
      <App {...params} />
    </PopupContainerContext.Provider>,
  )

  return {
    unmount: (): void => {
      controller.unmount()
    },
  }
}

export default {
  mount,
}
