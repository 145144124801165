import cn from 'classnames'
import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { Icon } from '@ui'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

import '@ui/Modal/index.scss'

type CloseEvent = React.MouseEvent<HTMLButtonElement | HTMLDivElement>

export interface ModalProps {
  children: ReactNode
  opened: boolean
  onClose?: (event?: CloseEvent) => void
  closeAfterTransition?: boolean
  fullScreen?: boolean
  className?: string
  title?: ReactNode | true
  header?: ReactElement | null
  footer?: ReactElement | null | false
  maxWidth?: Size
  fullWidth?: boolean
  fullHeight?: boolean
}

const Modal = ({
  children,
  fullScreen,
  fullWidth,
  fullHeight,
  header,
  footer,
  opened,
  onClose,
  title,
  className,
  maxWidth = 'lg',
  closeAfterTransition,
}: ModalProps): ReactElement | null => {
  const desktop = !useIsMobile()
  const modalRef = useRef<HTMLDivElement>(null)
  const backdropRef = useRef<HTMLDivElement>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(opened)
  const popupContainer = useContext(PopupContainerContext)

  const updateBodyStyles = useCallback((isOpened: boolean): void => {
    document.body.style.paddingRight = isOpened ? window.innerWidth - document.body.clientWidth + 'px' : ''
    document.body.style.overflow = isOpened ? 'hidden' : ''
    document.body.style.touchAction = isOpened ? 'none' : ''
  }, [])

  const openModal = useCallback(() => {
    updateBodyStyles(true)
    setIsModalVisible(true)
  }, [updateBodyStyles])

  const closeModal = useCallback(
    (event?: CloseEvent) => {
      const hideModal = (): void => {
        updateBodyStyles(false)
        setIsModalVisible(false)
        if (closeAfterTransition) {
          onClose?.(event)
        }
      }

      if (modalRef.current) {
        modalRef.current.classList.replace('ui-modal--opened', 'ui-modal--closed')
        modalRef.current.addEventListener('animationend', hideModal, { once: true })
      } else {
        hideModal()
      }
    },
    [closeAfterTransition, onClose, updateBodyStyles],
  )

  const handleCloseAction = (event: CloseEvent): void => {
    if (closeAfterTransition) {
      closeModal(event)
    } else {
      onClose?.(event)
    }
  }

  useEffect(() => {
    if (opened) openModal()
    else closeModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])
  useEffect(() => {
    return () => {
      updateBodyStyles(false)
    }
  }, [updateBodyStyles])

  if (!isModalVisible && !opened) return null

  const modalClassNames = bem('ui-modal', {
    opened,
    closed: !opened,
    'full-screen': fullScreen,
    'full-width': fullWidth,
    'full-height': fullHeight,
    [maxWidth]: true,
    desktop,
  })
  const classNames = cn(modalClassNames, className)

  return createPortal(
    <>
      <div
        data-tag="modal-overlay"
        ref={backdropRef}
        onClick={handleCloseAction}
        className={bem('ui-modal', 'overlay')}
      />
      <div data-tag="modal-window" ref={modalRef} className={classNames}>
        {title != null && (
          <div className="ui-modal__header" data-tag="modal-header">
            <div className="row space-between">
              <div className="w-100">{title}</div>
              <button onClick={handleCloseAction} className="ui-modal__close-button">
                <Icon name="cross" size="large" data-tag="modal-close" />
              </button>
            </div>
            {header}
          </div>
        )}
        <div data-tag="modal-body" className="ui-modal__content">
          {children}
        </div>
        {footer && (
          <div data-tag="modal-footer" className="ui-modal__footer">
            {footer}
          </div>
        )}
      </div>
    </>,
    popupContainer.current ?? /* istanbul ignore next: not reachable */ document.body,
  )
}

export default Modal
