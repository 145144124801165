import cn from 'classnames'
import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react'

import bem from '@lib/bem'
import Icon from '@ui/Icon'
import InputLabel from '@ui/Input/Label'

import '@ui/Input/index.scss'

type InputType = 'text' | 'date' | 'number'

const DATE_LIMIT_VALUE = 10

export type InputElement = HTMLInputElement | HTMLTextAreaElement

export interface InputSlotProps {
  onFocus: () => void
  onBlur: () => void
  className: string
}

export interface InputSlotRef {
  focus: () => void
}

export interface InputProps<SlotExtraProps = never> {
  value?: string
  onChange?: (value: string) => void
  onFocus?: (e: FocusEvent<InputElement>) => void
  onBlur?: (e: FocusEvent<InputElement>, value: string) => void
  onKeyDown?: (e: KeyboardEvent<InputElement>) => void
  onPressEnter?: (e: KeyboardEvent<InputElement>) => void
  label?: string | null
  iconBefore?: ReactNode
  iconAfter?: ReactNode
  disabled?: boolean
  errorMessage?: string | null
  name?: string
  required?: boolean
  type?: InputType
  max?: string
  trim?: boolean
  placeholder?: string | boolean
  multiline?: boolean
  maxLength?: number
  resettable?: boolean
  autoFocus?: boolean
  autoComplete?: string
  dataTag?: string
  slot?: {
    Component: React.ComponentType<InputSlotProps & SlotExtraProps>
    props: SlotExtraProps
  }
}

const Input = <SlotExtraProps = never,>(props: InputProps<SlotExtraProps>): ReactElement => {
  const {
    iconBefore,
    iconAfter,
    label,
    disabled,
    errorMessage,
    onFocus,
    onBlur,
    onKeyDown,
    name,
    required,
    type = 'text',
    max,
    maxLength,
    trim = true,
    placeholder,
    multiline,
    onPressEnter,
    resettable,
    autoFocus,
    autoComplete,
    dataTag,
    onChange,
    value,
    slot,
  } = props
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)
  const inputSlotRef = useRef<InputSlotRef>(null)

  const labelClassNames = cn('ui-input-label', {
    elevated: isFocused || value || placeholder,
  })
  const wrapperClassNames = bem('ui-input-wrapper', {
    active: isActive,
    focused: isFocused,
    labeled: label,
    error: errorMessage,
    multiline,
    resettable,
    disabled,
    'icon-before': iconBefore,
    'icon-after': iconAfter,
  })

  const applyFocusStyles = useCallback(() => setIsFocused(true), [])
  const handleFocus = (e: FocusEvent<InputElement>): void => {
    setIsFocused(true)
    onFocus?.(e)
  }

  const resetFocusStyles = useCallback((): void => {
    setIsFocused(false)
    setIsActive(false)
  }, [])

  const handleBlur = (e: FocusEvent<InputElement>): void => {
    resetFocusStyles()
    const value = trim ? e.target.value.trim() : e.target.value
    onChange?.(value)
    onBlur?.(e, value)
  }
  const handleKeyDown = (e: KeyboardEvent<InputElement>): void => {
    /* istanbul ignore next */
    if (type === 'date' && e.code === 'Space') e.preventDefault()

    setIsActive(true)
    onKeyDown?.(e)

    if (e.key === 'Enter') {
      onPressEnter?.(e)
    }
  }

  const handleChange = (e: ChangeEvent<InputElement>): void => {
    /* istanbul ignore next: Chrome behavior can't be reproduced in cypress for date input */
    if (type === 'date' && e.target.value.length > DATE_LIMIT_VALUE) return
    /* istanbul ignore next: cover in https://distribusion.atlassian.net/browse/OWL-2608 */
    if (multiline) {
      e.target.style.minHeight = e.target.scrollHeight + 'px'
    }

    onChange?.(e.target.value)
  }

  const handleReset = (): void => {
    onChange?.('')
  }

  const inputProps = {
    disabled: disabled,
    value,
    className: 'ui-input',
    type,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    name,
    max,
    maxLength,
    placeholder: typeof placeholder === 'string' ? placeholder : undefined,
    autoFocus,
    autoComplete,
    'data-tag': dataTag,
  }

  return (
    <div data-tag={dataTag && `${dataTag}-wrapper`}>
      <div className={wrapperClassNames} onClick={() => inputSlotRef.current?.focus()}>
        <div className={bem('ui-input-wrapper', 'icon')}>{iconBefore}</div>
        {label && <InputLabel className={labelClassNames} text={label} required={required} />}
        {slot && (
          <slot.Component
            ref={inputSlotRef}
            className={inputProps.className}
            onBlur={resetFocusStyles}
            onFocus={applyFocusStyles}
            {...slot.props}
          />
        )}
        {!slot && <>{multiline ? <textarea {...inputProps} rows={3} /> : <input {...inputProps} />}</>}
        <div className={bem('ui-input-wrapper', 'icon', { after: true })}>{iconAfter}</div>
        {resettable && value && <Icon name="cross" size="small" onClick={handleReset} className="ui-input-reset" />}
      </div>
      {errorMessage && (
        <div className="row start ui-input-error-message">
          <Icon name="alert" size="small" />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  )
}

export default Input
