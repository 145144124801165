import React, { ReactElement, useEffect, useMemo } from 'react'

import ErrorBoundary from '@components/ErrorBoundary'
import ErrorPage from '@components/ErrorPage'
import Initializer from '@components/Initializer'
import Loader from '@components/Loader'
import config from '@config'
import { AppContextProvider } from '@context/app'
import analytics from '@lib/analytics'
import amplitude from '@lib/analytics/amplitude'
import assert from '@lib/assert'
import { QueryClientProvider } from '@lib/queryClient'
import style from '@lib/style'
import { useAnalyticsSettings } from '@loaders/analyticsSettings'
import { useCarrierLoader } from '@loaders/carrier'
import { useSettings } from '@queries/settings'
import Widget from '@sdk/Route/Widget'
import { useParams } from '@stores/params'

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import '../../index.scss'

interface SDKRouteProps {
  partnerNumber: RetailerNumber
  carrierCode: CarrierCode
  departureStation: StationCode
  arrivalStation: StationCode
  locale: Locale
  target: SDK.Target
  currency?: Currency
}

const Route = (props: SDKRouteProps): ReactElement | null => {
  const [, setParams] = useParams()
  const [settings] = useSettings()

  const currency = props.currency ?? config.fallback.currency.default

  const {
    partnerNumber: retailerPartnerNumber,
    carrierCode: marketingCarrierCode,
    departureStation,
    arrivalStation,
    locale,
    target,
  } = props

  assert.positive(retailerPartnerNumber, '`partnerNumber` parameter is invalid')
  assert.notNull(marketingCarrierCode, '`carrierCode` parameter is invalid')
  assert.notNull(departureStation, '`departureStation` parameter is invalid')
  assert.notNull(arrivalStation, '`arrivalStation` parameter is invalid')

  const carrier = useCarrierLoader({ marketingCarrierCode, params: { currency, locale } })

  useEffect(() => {
    setParams({ retailerPartnerNumber, locale, currency, marketingCarrierCode })
  }, [marketingCarrierCode, currency, locale, retailerPartnerNumber, setParams])

  useAnalyticsSettings(
    { retailerPartnerNumber },
    {
      onSuccess: data => {
        analytics.init(data, { only: ['amplitude'] })
        amplitude.viewWidget()
      },
    },
  )

  useEffect(() => style.applyCustomColors(settings.colors), [settings.colors])

  return (
    carrier.data && (
      <Widget
        carrier={carrier.data}
        retailerPartnerNumber={retailerPartnerNumber}
        marketingCarrierCode={marketingCarrierCode}
        departureStation={departureStation}
        arrivalStation={arrivalStation}
        locale={locale}
        currency={currency}
        target={target}
      />
    )
  )
}

const SDKInitializer = (props: SDKRouteProps): ReactElement => {
  const { partnerNumber, locale, carrierCode } = props
  const currency = props.currency ?? config.fallback.currency.default
  const initializerParams = useMemo(
    () => ({
      retailerPartnerNumber: partnerNumber > 0 ? partnerNumber : undefined,
      marketingCarrierCode: carrierCode,
    }),
    [carrierCode, partnerNumber],
  )
  const [_, settingsQuery] = useSettings()
  const carrier = useCarrierLoader({ marketingCarrierCode: carrierCode, params: { currency, locale } })
  const isLoading = settingsQuery.isFetching || carrier.isLoading
  const error = settingsQuery.error || carrier.error

  return (
    <Initializer locale={locale} params={initializerParams}>
      <Loader loading={isLoading}>
        {!!error && <ErrorPage error={error} />}
        {!error && <Route {...props} />}
      </Loader>
    </Initializer>
  )
}

const App = (props: SDKRouteProps): ReactElement => {
  return (
    <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
      <AppContextProvider retailerPartnerNumber={props.partnerNumber} marketingCarrierCode={props.carrierCode}>
        <QueryClientProvider>
          <SDKInitializer {...props} />
        </QueryClientProvider>
      </AppContextProvider>
    </ErrorBoundary>
  )
}

export default App
