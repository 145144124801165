import { isAfter, subHours } from 'date-fns'

import { BookingAncillaries } from '@api/booking'
import config from '@config'
import {
  categoryMappings,
  icons,
  SEAT_CODE,
  OFFLOADING_CODE,
  MOTORCYCLE_CODE,
  SEGMENT_ANCILLARY,
  PAX_CATEGORY,
  RESERVATION_ANCILLARIES,
  PAX_CARRIER_ANCILLARY,
  SEPARATOR,
  STATIC_PRICE_ANCILLARIES,
} from '@lib/ancillary/mappings'
import checkout, { PassengerSeatInfo } from '@lib/checkout'
import currency from '@lib/currency'
import dateUtils from '@lib/date'
import passengerUtils from '@lib/passengers'
import utils from '@lib/utils'
import { CheckoutFormData, Seats } from '@pages/Checkout/hooks/useInitialFormValues'
import { PassengerData } from '@stores/checkout'

export interface AncillariesInfo {
  price: Money
  name: string
  count: number
}

export type AncillariesFormData = Record<KnownAncillary, Ancillary.Item[]>

const ancillariesWithUpToDateProperties = ['SEAT']

const isMandatory = (ancillary: Ancillary.Item): boolean =>
  ancillariesWithUpToDateProperties.includes(ancillary.category) ? ancillary.mandatoryForAdmission : false
const isPriceIncluded = (ancillary: Ancillary.Item): boolean =>
  ancillariesWithUpToDateProperties.includes(ancillary.category) ? ancillary.includedInPrice : false

const getCategoryFromCode = (code: string): AncillaryCategory => {
  const upperCode = code.toUpperCase()

  const { category } = categoryMappings.find(({ codes }) => codes.includes(upperCode)) ?? { category: 'UNKNOWN' }

  return category
}

const getIcon = (code: AncillaryCategory): string | undefined => icons[code]

const getByCategory = (category: AncillaryCategory, ancillaries: Ancillary.Item[] = []): Ancillary.Item[] =>
  ancillaries.filter(item => item.category === category)

const getBookingAncillariesParams = (ancillaries: AncillariesFormData): BookingAncillaries[] =>
  Object.entries(ancillaries).flatMap(([_, value]) =>
    value
      .filter(({ level }) => level === 'booking')
      .map(({ price, initialPrice, code, segmentIndex }) => {
        const isReturnTrip = price.fractional / initialPrice?.fractional > 1
        const multiplier = Number(isReturnTrip) + 1
        const filtered = value.filter(a => a.code === code && a.segmentIndex === segmentIndex)

        return utils.object.compact<BookingAncillaries>({
          code,
          segmentIndex,
          price: initialPrice?.fractional ?? price.fractional,
          quantity: filtered.length * multiplier,
        })
      })
      .filter(
        (item: BookingAncillaries, index: number, self: BookingAncillaries[]) =>
          item.segmentIndex !== undefined || self.findIndex(s => s.code === item.code) === index,
      ),
  )

const buildSeatAncillary = (index: number, seats: Seats, carrierCode?: string | null): PassengerSeatInfo[] =>
  checkout.getPassengerSeats(index, seats).reduce<PassengerSeatInfo[]>((acc, curr) => {
    const common = { ...curr, quantity: 1, code: SEAT_CODE, segmentIndex: Number(curr.segmentIndex) }

    if (carrierCode && config.seatAncillaryCarriers.includes(carrierCode))
      return [...acc, { ...common, customAttributes: [{ key: 'seat_code', value: curr.seatCode }] }]
    if (!curr.price) return acc

    return [...acc, common]
  }, [])

const getPaxAncillariesParams = (
  data: CheckoutFormData,
  index: number,
  seatAncillaries?: boolean,
): BookingAncillaries[] | null => {
  const ancillaries = Object.entries(data.ancillaries)
    .flatMap(([_, value]) =>
      value
        .filter((item: Ancillary.Item) => item.level === 'pax' && Number(item.passengerIndex) - 1 === index)
        .flatMap(({ code, initialPrice, price }: Ancillary.Item) => {
          const isOneWayTrip = price.fractional / initialPrice?.fractional === 1
          const ancillary = {
            code,
            price: initialPrice.fractional,
            quantity: 1,
            segmentIndex: 0,
          }

          return isOneWayTrip
            ? ancillary
            : new Array(2).fill(ancillary).map((item, segmentIndex) => ({ ...item, segmentIndex }))
        }),
    )
    .filter(item => item.code)

  const totalAncillaries = [...ancillaries]
  if (seatAncillaries && data.seats) {
    // Temporarily solution for SEAT code
    totalAncillaries.push(...buildSeatAncillary(index, data.seats, data.marketingCarrierCode))
  }

  return totalAncillaries.length ? totalAncillaries : null
}

interface PriceInfoConfig {
  includeReservation?: boolean
  showIncluded?: boolean
}

//ToDo: We now have ancillary prices which are calculated on the server side or on the client side.
// With the current structure is has become difficult to keep track if the ancillary should be included in the price,
// so we need to refactor this part in the close future and remove the second function argument.

// istanbul ignore next
const getPriceInfo = (
  _ancillaries: Record<string, Ancillary.Item[]>,
  { includeReservation, showIncluded }: PriceInfoConfig,
): AncillariesInfo[] => {
  let ancillaries = _ancillaries
  if (!includeReservation) {
    ancillaries = Object.entries(ancillaries).reduce(
      (acc, [key, value]) => (RESERVATION_ANCILLARIES.includes(key) ? acc : { ...acc, [key]: value }),
      {},
    )
  }

  return Object.entries(ancillaries)
    .map(([key, value]) => ({
      name: key,
      count: value.length,
      price: value.reduce(
        (acc: Money, curr: Ancillary.Item) => {
          if (!showIncluded && isPriceIncluded(curr)) return acc
          if (RESERVATION_ANCILLARIES.includes(key) && !curr.active) return acc
          return {
            fractional: acc.fractional + curr.price.fractional,
            currency: curr.price.currency,
          }
        },
        { fractional: 0, currency: 'EUR' as Currency },
      ),
    }))
    .filter(({ price }) => !!price.fractional)
}

const getTotalAmount = (ancillaries: AncillariesFormData): number =>
  getPriceInfo(ancillaries, { includeReservation: false }).reduce((acc, cur) => acc + cur.price.fractional, 0)

const getMaxCount = (passengers: PassengerData[], excludedType: string[]): number =>
  passengers.filter(item => !excludedType.includes(item.type)).length

const getLuggageDescription = (ancillary: Ancillary.Item): string =>
  ancillary.customAttributes
    .map(item => {
      switch (item.key) {
        case 'max_weight_in_kgs':
          return `${item.value} kg`
        case 'max_dimensions_in_cm':
          return `${item.value} cm`
        /* istanbul ignore next */
        default:
          return null
      }
    })
    /* in case we don't have such item.key we should filter out null value */
    .filter(item => item)
    .join(SEPARATOR)

const getCarbonDescription = (ancillary: Ancillary.Item): string | undefined =>
  ancillary.customAttributes.find(attr => attr.key === 'terms')?.value

const getVehicleMaxCount = (maxCount: number, ancillaries: Ancillary.Item[], code?: string): number => {
  const ancillariesCount = ancillaries.filter(item => item.code !== OFFLOADING_CODE).length
  const motorcycleCount = ancillaries.filter(item => item.code === MOTORCYCLE_CODE).length

  if (code === OFFLOADING_CODE) return ancillariesCount - motorcycleCount

  return maxCount === ancillariesCount ? 0 : maxCount
}

const getSegmentIndex = (ancillary: Ancillary.Item): number | null => {
  // ID is used as indicator to define if ancillary related to the booking level.
  // If it doesn't contain a number at the end it relates to the booking level (all segments)
  const index = Number(ancillary.id.at(-1))

  return isNaN(index) ? null : index
}

const getLevel = (ancillary: Ancillary.Item, carrier: string): 'booking' | 'pax' => {
  if (PAX_CATEGORY.includes(ancillary.category)) return 'pax'
  if (PAX_CARRIER_ANCILLARY.includes(carrier)) return 'pax'

  return 'booking'
}

const updateSegmentIndex = (ancillaries: Ancillary.Item[], carrier: string): Ancillary.Item[] =>
  ancillaries.map(item => {
    const segmentIndex = getSegmentIndex(item)
    const level = getLevel(item, carrier)
    if (segmentIndex == null) return { ...item, isAllSegments: true, level }
    if (SEGMENT_ANCILLARY.includes(item.category)) return { ...item, segmentIndex, level }

    return { ...item, level }
  })

const filterBySegment = (ancillaries: Ancillary.Item[], segmentsCount: number): Ancillary.Item[] =>
  ancillaries.filter((item, _, self) => {
    const count = self.filter(i => i.code === item.code).length

    return item.isAllSegments || count === segmentsCount
  })

const isOffloadingOver = (ancillaries: Ancillary.Item[]): boolean => {
  const transport = ancillaries.filter(item => ![OFFLOADING_CODE, MOTORCYCLE_CODE].includes(item.code))
  const offloading = ancillaries.filter(item => [OFFLOADING_CODE].includes(item.code))

  return offloading.length > transport.length
}

const getPriceMultiplier = (category: AncillaryCategory, multiplier: number): number => {
  const DEFAULT = 1

  return STATIC_PRICE_ANCILLARIES.includes(category) ? DEFAULT : multiplier
}

const getLimitation = (category: AncillaryCategory, connection: Connection): boolean | null => {
  const { marketingCarrier, departureTime } = connection

  const limitation = config.ancillaryLimitation[marketingCarrier.code]?.[category]

  if (!limitation) return null

  const shiftedDeparture = subHours(dateUtils.parse(departureTime, 'UTC'), limitation)

  return isAfter(shiftedDeparture, dateUtils.today())
}

const clearAttributes = (ancillary: Ancillary.Item): Ancillary.Item => ({
  ...ancillary,
  customAttributes: ancillary.customAttributes.map(attribute => ({ ...attribute, value: null as any })),
})

const getPreselected = (formData: CheckoutFormData, preselected: string[], isInbound: boolean): AncillariesFormData => {
  const { vacancy, passengers, ancillaries } = formData

  const filtered = vacancy?.ancillaries.reduce<Ancillary.Item[]>((acc, curr) => {
    const existed = acc.find(item => item.code === curr.code)

    if (existed) existed.price = currency.sumPrice(existed.price, curr.price)
    if (preselected.includes(curr.code) && !existed) return [...acc, structuredClone(curr)]

    return acc
  }, [])

  if (!filtered?.length) return ancillaries

  const count = ancillaryUtils.getMaxCount(passengers, passengerUtils.getPassengerCode(['infant']))

  return new Array(count)
    .fill([...filtered, ...ancillaries.ACCOMMODATION])
    .flatMap((list, index) =>
      list.map((ancillary: Ancillary.Item) => {
        const multiplier = ancillaryUtils.getPriceMultiplier(ancillary.category, Number(isInbound) + 1)
        const price = { ...ancillary.price, fractional: ancillary.price.fractional * multiplier }

        return {
          ...ancillary,
          price: ancillary.isAllSegments ? price : ancillary.price,
          initialPrice: ancillary.price,
          passengerIndex: index + 1,
        }
      }),
    )
    .reduce((acc, curr) => {
      if (curr.category === 'INSURANCE') acc[curr.category].push(clearAttributes(curr))
      else acc[curr.category].push(curr)

      return acc
    }, getInitialFormData())
}

const getInitialFormData = (): AncillariesFormData => ({
  BICYCLE: [],
  LUGGAGE: [],
  PETS: [],
  EQUIPMENT: [],
  VEHICLE: [],
  ALERT: [],
  INSURANCE: [],
  SIMPLIFIED_INSURANCE: [],
  ACCOMMODATION: [],
  MEAL: [],
  CARBON: [],
  HELP: [],
  SEAT: [],
  METRO: [],
  LEAD: [],
  BOARDING: [],
  LOUNGE: [],
  FASTTRACK: [],
  TICKETSBYPOST: [],
  CHANGE: [],
  YOUNGCOACHCARD: [],
  SENIORCOACHCARD: [],
  DISABLEDCOACHCARD: [],
  FREESEAT: [],
})

const ancillaryUtils = {
  getPriceInfo,
  getTotalAmount,
  getByCategory,
  getBookingAncillariesParams,
  getMaxCount,
  getLuggageDescription,
  getVehicleMaxCount,
  getPaxAncillariesParams,
  updateSegmentIndex,
  getIcon,
  filterBySegment,
  isOffloadingOver,
  getInitialFormData,
  getCarbonDescription,
  buildSeatAncillary,
  getCategoryFromCode,
  getPriceMultiplier,
  getLimitation,
  clearAttributes,
  isMandatory,
  isPriceIncluded,
  getPreselected,
}
export default ancillaryUtils
