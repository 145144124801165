import config from '@config'

// Assign a value to window property so that Cypress can access it to mock its logic
const expose = (key: string, value: any): void => {
  /* istanbul ignore else: unreachable code inside test env */
  if (config.env === 'test') {
    ;(window as any)[key] = value
  }
}

const getDependency = (key: string): any => {
  /* istanbul ignore else: unreachable code inside test env */
  if (config.env === 'test') {
    return (window as any)[key]
  }
}

export default {
  expose,
  getDependency,
}
