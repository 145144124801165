import suggestionsAPI, { SuggestionEntry } from '@api/suggestion'
import amplitude from '@lib/analytics/amplitude'
import { makeLoader } from '@lib/loader'

interface UseSuggestionsParams {
  q: string
  retailerPartnerNumber: RetailerNumber
  fieldType: Location.Direction
  locale: Locale
  carriers?: CarrierCode[]
  departureLocation?: Location.Item | null
  locationType?: Location.Type[]
  enabled?: boolean
  notifyAmplitude: boolean
  filterCodes?: string[]
  count: number
}

const adjustLocale = (locale: Locale): Locale => {
  const [lang] = locale.split('-')

  return lang.toLowerCase() as Locale
}

const getChildren = (location: SuggestionEntry): SuggestionEntry[] => {
  if (location.type === 'city') return location.childrenLocations ?? []

  return []
}

const toSuggestions = (locations: SuggestionEntry[], locale: Locale): Location.Suggestion[] => {
  const resolveName = (location: SuggestionEntry): string => {
    const localeName = location.names.find(item => item.languageCode === locale)?.name

    if (localeName) return localeName

    return location.names[0].name
  }

  return locations.map(loc => ({
    type: loc.type,
    code: loc.code,
    countryCode: loc.countryCode,
    subdivisionCode: loc.subdivisionCode,
    name: resolveName(loc),
    children: toSuggestions(getChildren(loc), locale),
  }))
}

export const useSuggestionsLoader = makeLoader<UseSuggestionsParams, Location.Suggestion[]>({
  key: 'suggestions',
  debounce: 300,
  enabled: ({ enabled }) => enabled == null || enabled,
  loader: async ({
    q,
    carriers,
    departureLocation,
    locale,
    retailerPartnerNumber,
    notifyAmplitude,
    fieldType,
    filterCodes,
    count,
    locationType,
  }) => {
    const query = {
      q,
      rpn: retailerPartnerNumber,
      locale: adjustLocale(locale),
      carriers,
      departureLocation: departureLocation?.code,
      departureLocationType: departureLocation?.type,
      locationType,
      limitTopLevel: count,
      exclude_country_code: filterCodes,
    }

    const requestStartTime = performance.now()
    const response = await suggestionsAPI.load(query)
    const suggestions = toSuggestions(response.locations, locale)

    if (notifyAmplitude) {
      amplitude.suggestions.response({ queryString: q, response, fieldType, suggestions, requestStartTime })
    }

    return suggestions
  },
})
