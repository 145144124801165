import { Field, FieldProps, useFormikContext } from 'formik'
import React, { ReactElement, useState } from 'react'

import LocationAutocomplete, { LocationAutocompleteProps } from '@components/LocationAutocomplete'
import locationUtils from '@lib/location'
import * as validators from '@lib/validators'

interface LocationFieldProps extends Partial<LocationAutocompleteProps> {
  name: string
  fieldType: Location.Direction
  filterCodes?: string[]
  filterSuggestion: (data: Location.Suggestion[] | null) => Location.Suggestion[]
  disabled?: boolean
  dataTag?: string
}

const LocationField = ({ name, dataTag, ...rest }: LocationFieldProps): ReactElement => {
  const { setFieldValue, setFieldError } = useFormikContext()
  const [searchValue, setSearchValue] = useState<string>('')

  const handleReset = (): void => {
    setFieldValue(name, null, false)
  }

  const validateIsLocationSelected = validators.optionSelectedFactory(
    searchValue,
    (value: Location.NamedItem | undefined) => value && locationUtils.makeDisplayName(value),
  )

  const validate = (value?: Location.NamedItem): string | null | undefined => {
    return validators.required(searchValue) ?? validateIsLocationSelected(value)
  }

  const onOptionSelect = (value: Location.NamedItem): void => {
    setSearchValue(locationUtils.makeDisplayName(value))
    setFieldValue(name, value, false)
    setFieldError(name, undefined)
  }

  return (
    <Field name={name} validate={validate}>
      {({ field: { value, onBlur }, meta: { touched, error } }: FieldProps) => (
        <LocationAutocomplete
          {...rest}
          onInputBlur={onBlur}
          value={value}
          onChange={onOptionSelect}
          errorMessage={touched ? error : null}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          reset={handleReset}
          dataTag={dataTag}
        />
      )}
    </Field>
  )
}

export default LocationField
