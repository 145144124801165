import cn from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

import '@components/Loader/index.scss'

interface LoaderProps {
  loading: boolean
  children?: ReactNode
  title?: string
  width?: number
  height?: number
  className?: string
}

const Loader = ({ loading, children, title, width, height, className }: LoaderProps): ReactElement => {
  if (!loading) return children as ReactElement

  return (
    <div className={cn('loader column', className)}>
      <svg style={{ width, height }} className="loader__spinner" viewBox="25 25 50 50">
        <circle className="spinner__path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
      {title && <div className="loader__title">{title}</div>}
    </div>
  )
}

export default Loader
