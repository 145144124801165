import React, { Fragment, ReactElement, ReactNode } from 'react'

import LineSkeleton from '@components/Initializer/Skeletons/common/Line'
import useIsMobile from '@hooks/useIsMobile'
import { Skeleton } from '@ui'

interface CheckoutSkeletonProps {
  hideBGLine?: boolean
}

const CheckoutSkeleton = ({ hideBGLine }: CheckoutSkeletonProps): ReactElement => {
  const isMobile = useIsMobile()

  const card = (key: number): ReactNode => (
    <div key={key} className="cell">
      <div className="page-skeleton__section p-4">
        <div className="cell-12 mb-2">
          <Skeleton.Item height={30} />
        </div>
        <div className="cell-12 mb-2">
          <Skeleton.Item height={isMobile ? 513 : 165} />
        </div>
      </div>
    </div>
  )

  return (
    <>
      {!hideBGLine && <LineSkeleton height={64} />}
      <div className="column page-skeleton__page page-skeleton__checkout">
        <div className="column row-lg gap-5 p-sm-4">
          <div className="cell-8 cell-sm-12">
            <div className="column gap-5">{new Array(2).fill(card).map((card, index) => card(index))}</div>
          </div>
          <div className="cell-4 cell-sm-12">
            <div className="page-skeleton__section page-skeleton__checkout-submit p-4">
              <div className="cell-12 mb-lg-9 mb-3">
                <Skeleton.Item height={isMobile ? 40 : 392} />
              </div>
              <div className="cell-12">
                <Skeleton.Item height={56} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutSkeleton
