import { SdkMountProps } from '@lib/sdk'
import { SdkController } from '@lib/sdk/controller'
import Sentry from '@lib/sentry'

class SdkBuilder {
  private instances: Map<HTMLElement, SdkController> = new Map<HTMLElement, SdkController>()
  private initialized = false

  build(node: HTMLElement, props: SdkMountProps): SdkController {
    if (!this.initialized) {
      this.initializeSideEffects()
      this.initialized = true
    }

    return this.instances.get(node) ?? this.createController(node, props)
  }

  private initializeSideEffects() {
    try {
      Sentry.init({ global: false })
    } catch (e) {}
  }

  private createController(node: HTMLElement, props: SdkMountProps): SdkController {
    const controller = new SdkController(node, props)
    this.instances.set(node, controller)
    controller.onUnmount = () => this.instances.delete(node)
    return controller
  }
}

const sdkBuilder = new SdkBuilder()

export default sdkBuilder
