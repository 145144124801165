import carrierAPI, { CarrierRequest, CarrierResponse } from '@api/carrier'
import { makeLoader } from '@lib/loader'

interface UseCarrierLoaderParams {
  marketingCarrierCode?: string | null
  params: CarrierRequest
}

export const useCarrierLoader = makeLoader<UseCarrierLoaderParams, CarrierResponse>({
  key: 'carrier',
  enabled: ({ marketingCarrierCode }) => !!marketingCarrierCode,
  loader: async ({ marketingCarrierCode, params }) => await carrierAPI.load(marketingCarrierCode as string, params),
  cacheTime: 10 * 60 * 1000,
  staleTime: 10 * 60 * 1000,
})
