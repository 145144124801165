import { useMemo } from 'react'

import { SearchFormSettings } from '@components/SearchForm'
import useKavanaghIntegration from '@hooks/useKavanaghIntegration'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

const useSearchBarSettings = (): SearchFormSettings => {
  const [settings] = useSettings()
  const [params] = useParams()
  const { isJJKEnabled, amendmentJJKSettings } = useKavanaghIntegration()

  return useMemo(() => {
    if (isJJKEnabled && params.bookingId) return amendmentJJKSettings

    const { returnTrip, passengerType, tripTime, passengerTypesList, discountCodes, paxDiscountCodes } = settings
    return {
      returnTrip: returnTrip.enabled,
      passengerType: params.bookingId ? false : passengerType.enabled,
      tripTime: tripTime.enabled,
      discountCodes: discountCodes.enabled && discountCodes.displayOn === 'everywhere',
      seatProduct: settings.seatProduct.enabled,
      paxDiscountCodes:
        paxDiscountCodes.enabled &&
        passengerTypesList.enabled &&
        (paxDiscountCodes.displayOn === 'everywhere' || paxDiscountCodes.displayOn === 'search_results'),
    }
  }, [amendmentJJKSettings, isJJKEnabled, params.bookingId, settings])
}

export default useSearchBarSettings
