import cn from 'classnames'
import { Field, FieldProps } from 'formik'
import React, { ReactElement, useMemo } from 'react'
import { UseQueryResult } from 'react-query'

import { DiscountCardsResponse } from '@api/discountCards'
import { SearchFormState } from '@components/SearchForm'
import discountUtils from '@lib/discount'
import { useTranslation } from '@lib/i18n'
import { uniq } from '@lib/validators'
import { Icon } from '@ui'
import InputField from '@ui/Input/Field'

interface BookingDiscountsProps {
  classNames: Record<string, string[]>
  discounts: UseQueryResult<DiscountCardsResponse>
}

const BookingDiscounts = ({ classNames, discounts }: BookingDiscountsProps): ReactElement => {
  const { t } = useTranslation()

  const bookingCards = useMemo(() => discounts.data?.filter(c => c.context === 'booking'), [discounts])

  return (
    <>
      {bookingCards?.map(({ name, marketingName }) => (
        <div key={name} className={cn(...classNames.discountsContainer)}>
          <Field>
            {({ form }: FieldProps<string | null, SearchFormState>) => (
              <Field
                component={InputField}
                iconBefore={<Icon name={discountUtils.getIcon(name)} size="large" />}
                label={t(`searchBar.discounts.${name}`, { defaultValue: marketingName })}
                name={`cards.${name}`}
                validate={uniq(form.values.cards)}
              />
            )}
          </Field>
        </div>
      ))}
    </>
  )
}

export default BookingDiscounts
