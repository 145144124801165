import { MutableRefObject } from 'react'
import { Root } from 'react-dom/client'

import sdk, { SdkMountProps } from '@lib/sdk'

export class SdkController {
  root: Root
  popupContainer: MutableRefObject<HTMLElement>
  onUnmount?: () => void

  constructor(node: HTMLElement, props: SdkMountProps) {
    this.root = sdk.mountInShadowDom(node, props)
    this.popupContainer = sdk.mountPopupContainer(props)
  }

  render(children: React.ReactNode) {
    this.root.render(children)
  }

  unmount() {
    this.root.unmount()
    this.onUnmount?.()
  }
}
