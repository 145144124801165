import cn from 'classnames'
import { Field, FieldProps, Form, useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useMemo } from 'react'

import PassengersDropdown from '@components/PassengersDropdown'
import BookingDiscounts from '@components/SearchForm/BookingDiscounts'
import DatesFieldSet from '@components/SearchForm/DatesFieldSet'
import DepartureTimeField from '@components/SearchForm/DepartureTimeField'
import { SearchFormSettings, SearchFormState } from '@components/SearchForm/index'
import LocationFieldSet from '@components/SearchForm/LocationFieldSet'
import TripDirectionField from '@components/TripDirection/Field'
import config from '@config'
import useKavanaghIntegration from '@hooks/useKavanaghIntegration'
import { useTranslation } from '@lib/i18n'
import { passengerType } from '@lib/validators'
import { useDiscounts } from '@queries/dicounts'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Button } from '@ui'
import CheckboxField from '@ui/Checkbox/Field'

const LAYOUT_CLASS_NAMES = {
  row: {
    container: ['row', 'gap-3', 'wrap'],
    locationsItem: ['cell-4', 'grow', 'cell-md-12'],
    tripTypeContainer: ['cell-12', '-order-1'],
    datePickerContainer: ['cell-3', 'grow'],
    timeContainer: ['cell-2', 'grow'],
    passengersContainer: ['cell-2', 'grow'],
    discountsContainer: ['cell-2', 'grow'],
    submitContainer: ['cell'],
    addonContainer: ['cell-12', 'order-6'],
  },
  'row-2': {
    container: ['row', 'gap-3', 'wrap'],
    locationsItem: ['cell-8', 'grow', 'order-1'],
    tripTypeContainer: ['cell-12', '-order-1'],
    datePickerContainer: ['cell-4', 'grow', 'order-3'],
    timeContainer: ['cell-4', 'grow', 'order-3'],
    passengersContainer: ['cell-4', 'grow', 'order-2'],
    discountsContainer: ['cell-4', 'grow', 'order-4'],
    submitContainer: ['cell-12', 'cell-lg-4', 'order-5'],
    addonContainer: ['cell-12', 'order-4', 'order-lg-6'],
  },
  column: {
    container: ['column', 'gap-5'],
    locationsItem: ['cell'],
    tripTypeContainer: ['cell'],
    datePickerContainer: ['cell'],
    timeContainer: ['cell'],
    passengersContainer: ['cell'],
    discountsContainer: ['cell'],
    submitContainer: ['cell', 'mt-1'],
    addonContainer: ['cell', 'mt-1'],
  },
}

interface FormContentProps extends Directional {
  passengerList: Passenger.Type[]
  settings: SearchFormSettings
  isDepartureDateDisabled?: (date: Date) => boolean
}

const floatingElements: (keyof SearchFormSettings)[] = ['accommodation', 'seatProduct']

const getPassengers = (values: Passenger.Param[], fallback: Passenger.Type): Passenger.Param[] => {
  if (values.length) return values

  return [{ type: fallback.code, maxAge: fallback.maxAge, cards: fallback.cards, pax: 1 }]
}

const FormContent = (props: FormContentProps): ReactElement => {
  const { settings, direction = 'row', isDepartureDateDisabled } = props
  const { t } = useTranslation()
  const [{ passengerTypesList, weeklyTrip }] = useSettings()
  const { filterJJKTypes, isJJKEnabled } = useKavanaghIntegration()
  const [{ retailerPartnerNumber }] = useParams()
  const { values, setFieldValue } = useFormikContext<SearchFormState>()
  const { departureLocation, arrivalLocation, tripDirection, passengers } = values

  const discounts = useDiscounts({ enabled: settings.paxDiscountCodes || settings.discountCodes })
  const isWeeklyEnabled = props.settings.weekly ?? weeklyTrip.enabled
  const showDirection = settings.returnTrip || isWeeklyEnabled

  const passengerList = useMemo(() => {
    const tripData = { departure: departureLocation?.code, arrival: arrivalLocation?.code, direction: tripDirection }

    if (isJJKEnabled) return filterJJKTypes(props.passengerList, tripData)

    return props.passengerList
  }, [isJJKEnabled, filterJJKTypes, props.passengerList, departureLocation, arrivalLocation, tripDirection])

  const classNames = useMemo(() => {
    if (direction === 'column') return LAYOUT_CLASS_NAMES[direction]

    const extraFieldsAmount = Object.entries(settings).filter(
      ([key, value]) => value && !floatingElements.includes(key as keyof SearchFormSettings),
    ).length

    if (extraFieldsAmount >= 3) return LAYOUT_CLASS_NAMES['row-2']

    return LAYOUT_CLASS_NAMES.row
  }, [direction, settings])

  const handleMaxCount = (totalCount: number): number =>
    totalCount >= config.amtrakMaxPassengers ? 0 : config.amtrakMaxPassengers

  const handleValidate = (value: Passenger.Param[]): string | undefined => passengerType(value, retailerPartnerNumber)

  useEffect(() => {
    if (!passengerTypesList.enabled || !isJJKEnabled) return

    const codes = passengerList.map(({ code }) => code)
    const isSame = passengers.every(({ type }) => codes.includes(type))

    if (isSame) return

    const filteredPassengers = passengers.filter(({ type }) => codes.includes(type))

    setFieldValue('passengers', getPassengers(filteredPassengers, passengerList[0]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureLocation, arrivalLocation, tripDirection])

  return (
    <Form id="search-form">
      <div className={cn('search-form', ...classNames.container)}>
        <div className={cn('search-form__locations', ...classNames.locationsItem)}>
          <LocationFieldSet direction={direction} disabled={settings.disabledLocation} />
        </div>
        {showDirection && (
          <div className={cn('search-form__trip-direction-radio-group', ...classNames.tripTypeContainer)}>
            <Field
              component={TripDirectionField}
              name="tripDirection"
              twoWay={settings.returnTrip}
              weekly={isWeeklyEnabled}
            />
          </div>
        )}
        <div className={cn(...classNames.datePickerContainer)}>
          <DatesFieldSet
            calendarPlacement={settings.calendarPlacement}
            isDepartureDateDisabled={isDepartureDateDisabled}
            searchDays={weeklyTrip.searchDays}
          />
        </div>
        {settings.tripTime && (
          <div className={cn(...classNames.timeContainer)}>
            <DepartureTimeField name="departureTime" label={t('searchBar.departureTimeLabel')} />
          </div>
        )}
        {settings.passengerType && (
          <div className={cn(...classNames.passengersContainer)}>
            <Field name="passengers" validate={handleValidate}>
              {({ field: { value, name }, form: { setFieldValue, errors } }: FieldProps) => (
                <PassengersDropdown
                  label={t('searchBar.passengers.label')}
                  value={value}
                  onChange={passengers => {
                    setFieldValue(name, passengers)
                  }}
                  availableTypes={passengerTypesList.enabled ? passengerList : null}
                  getMaxCount={passengerTypesList.enabled ? handleMaxCount : null}
                  errors={errors}
                  discounts={discounts}
                  showDiscounts={settings.paxDiscountCodes}
                  showAge={passengerTypesList.multiple}
                />
              )}
            </Field>
          </div>
        )}
        {settings.discountCodes && <BookingDiscounts classNames={classNames} discounts={discounts} />}
        {settings.accommodation && (
          <div className={cn(...classNames.addonContainer, 'search-form__accommodation')}>
            <Field
              component={CheckboxField}
              checked={values.accommodation}
              name="accommodation"
              label={t('searchBar.accommodation')}
            />
          </div>
        )}
        {settings.seatProduct && (
          <div className={cn(...classNames.addonContainer, 'search-form__addon')}>
            <Field
              component={CheckboxField}
              checked={values.seatProduct}
              name="seatProduct"
              label={t('searchBar.seatProduct')}
            />
          </div>
        )}
        <div className={cn('search-form__submit-button', ...classNames.submitContainer)}>
          <Button buttonType="submit">{t('searchBar.search')}</Button>
        </div>
      </div>
    </Form>
  )
}

export default FormContent
